import React, { useContext, useEffect, useState } from 'react'
import styles from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import uploadFile from '../../../Assets/uploadFile.png'
import { api_call_token, formDataApi } from '../../../Utils/Network';
import { UserCredsContext } from '../../../ContextApi/UserCredsContext/UserCredsContext';
import backArrowbtn from '../../../Assets/backArrow.png'
import dummy from "../../../Assets/pp.pdf"
import GetAppIcon from '@material-ui/icons/GetApp';
import { Button } from '@material-ui/core';

const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // textAlign: "center",
  // alignItems:"center"
};

function LearnZoneAssignment() {
  const [open, setOpne] = useState(false)
  const [formSumbitesOpne, setFormSumbimtedOpne] = useState(false)
  const [qData, setQdata] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [dummyLink, setDummyLink] = useState("")
  const { user } = useContext(UserCredsContext);

  const { id, ppt, courseId } = useParams();

  const history = useHistory();
  const handleBackClick = () => {
    if (ppt === "worksheets") {
      history.push(`/my-space/1/${courseId}/worksheets`);
    } else if (ppt === "do-it-your-self") {
      history.push(`/my-space/1/${courseId}/do-it-your-self`);
    }

    // onTakeTest(v);
  };

  const submitTestOpne = () => {
    setOpne(true)
  }

  const getsubjectivedata = () => {
    api_call_token
      .get(`content/question/listing/${id}/`)
      .then((res) => {
        setQdata(res?.data?.data)
        console.log(res?.data?.data?.questions, "getsubjectivedata");
        setDummyLink(res?.data?.data?.file)
      })
      .catch((error) => {
        console.log(error);
      })
  }
  console.log(dummyLink, "sjdhauidhadjasndajkhdajkbd");
  useEffect(() => {
    getsubjectivedata();
  }, [])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const viewResultClose = () => setOpne(false);
  const viewsubmitClose = () => setFormSumbimtedOpne(false)

  // ///////////////////////////////////////////////////////
  //     from upload api
  // //////////////////////////////////////////////////////

  const submitData = async (e) => {
    e.preventDefault()
    console.log(selectedFile, "findAWHAT");
    let data = {
      // ...submitValue,
      // answer_file: file,

      test_id: id,
      questions: [115],
      answer_file: selectedFile,
      user_id: user,

    }
    const formData = new FormData();
    if (data.test_id) formData.append("test_id", data.test_id);
    data.questions.forEach((questions) => {
      formData.append("questions", data.questions)
    })
    // if (data.questions) formData.append("questions", data.questions)
    if (data.answer_file) formData.append("answer_file", data.answer_file)
    if (data.user_id) formData.append("user_id", data.user_id)


    const apiEnd = "";
    // const apiUrl = 'content/V1/subjective/'
    var apiUrl = '';
    if(ppt === "worksheets"){
      apiUrl = 'content/V1/subjective/'
    } else if(ppt === "do-it-your-self"){
      apiUrl = 'content/v1/self_do_it_ans/'  
    }

    try {
      const res = await formDataApi(apiEnd, formData, false, apiUrl);
      console.log(res, "from submited");
      if (res?.status == 200 || res?.status == 201) {
        setSelectedFile();
        viewResultClose()
        setFormSumbimtedOpne(true)
      }

    } catch (error) {
      // setDisabledData(false);
      console.log(error);
    }
  }


  const downloadpdf = (file) => {
    console.log(file, "hgdkaudgadbajdakdjhd");
    if (file) {
      // Create an anchor element
      const anchor = document.createElement('a');
      anchor.href = file;
      anchor.download = 'worksheet.pdf'; // Specify the desired filename for the downloaded file
      anchor.click();

      // Clean up the anchor element
      URL.revokeObjectURL(anchor.href);
    } else {
      console.error("File URL is not valid or missing.");
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div
        onClick={handleBackClick}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        {/* <ArrowBackIcon /> */}
        <img src={backArrowbtn} alt='backarrow' />
        <p>Back</p>
      </div>
      <div className={styles.QuestionBox}>
        {/* <a href={dummyLink} download="test-pdf"  target="_blank" rel="noopener noreferrer" className={styles.downloadepaper} >
        <GetAppIcon /> Download Worksheet
        </a> */}
        {/* <button className={styles.downloadepaper} onClick={() => downloadpdf(qData?.file)}> <GetAppIcon /> Download Worksheet </button> */}
        {
          qData?.questions?.map((v, index) => {
            return (
              <div className={styles.question} key={v?.id}>
                <h5 >Question {v?.question_number}</h5>
                <p>{v?.title} </p>
                <div className={styles.QuestionMarks}>{v?.positive_marks} marks</div>
              </div>
            )
          })
        }
        {/* <div className={styles.question}>
            <h5 >Question 1</h5>
            <p>The point of using Lorem Ipsum  The point of using Lorem Ipsuhe point of using Lorem Ipsum  ?  </p>
            <div className={styles.QuestionMarks}>10 marks</div>
        </div> */}
        <div className={styles.BtnBox}>
          <button className={styles.btnanswer} style={{ marginRight: "30px" }} onClick={submitTestOpne}>{
          (ppt === "worksheets")? 
              "Upload answer sheet" : 
           (ppt === "do-it-your-self") ?
           "Upload Activity" : ""
            
            
            }</button>
          {/* <button className={styles.btnanswer}>view model answer</button> */}
        </div>
      </div>

      <Modal
        open={open}
        onClose={viewResultClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.mobileS}>
          <div className={styles.closebtn}>
            <CloseIcon onClick={viewResultClose} />
          </div>
          <div className={styles.resultModel}>
            <h3>upload your answer sheet</h3>
            <p>File Name :</p>
            <form onSubmit={submitData}>
              <div className={styles.btnContainer}>
                <label for="inputTag" className={styles.inputfile}>
                  <div className={styles.ufImgContainer}>
                    <img src={uploadFile} />
                    <span> {selectedFile ? selectedFile.name : 'Upload'}</span>
                  </div>
                  <p className={styles.fileFormat}>jpg,png,txt,zip</p>
                  <input id='inputTag' type='file' onChange={handleFileChange} accept=' .jpg, .png, .txt,.zip, '></input>
                </label>
              </div>
              <button className={styles.uploadBtn} type='submit'>
                Submit
              </button>
            </form>
          </div>
        </Box>
      </Modal>
      <Modal
        open={formSumbitesOpne}
        onClose={viewsubmitClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.mobileS}>
          <div className={styles.closebtn}>
            <CloseIcon onClick={viewsubmitClose} />
          </div>
          <div className={styles.resultModel}>
            <h3>Answer Submited Successfull</h3>
            <p>Thank you for submitting the test</p>
          </div>
          <button className={styles.uploadBtn} type='submit' onClick={handleBackClick}>
            ok
          </button>
        </Box>
      </Modal>
    </div>
  )
}

export default LearnZoneAssignment
import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.css";
import testimg from "../../../Assets/myspacetest.png";
import testtime from "../../../Assets/testtime.png";
import testmark from "../../../Assets/testmarks.png";
import testQues from "../../../Assets/testques.png";
import { api_call_token } from "../../../Utils/Network";
import { Skeleton } from "@material-ui/lab";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import notest from "../../../Assets/no-test.png";
import { tr } from "date-fns/locale";

function AssingmentPage({ onTakeTest, setSelectCourse }) {
  const [assingmentList, setAssingmentList] = useState([]);
  const [loading, setLoading] = useState(0);
  const [page, setPage] = useState(1);
  const [empty, setEmpty] = useState(false);
  const [lastElement, setLastElement] = useState(null);
  const [maxpage, setmaxPage] = useState(1);
  const { id, ppt, courseId } = useParams();
  const history = useHistory();

  const observer = useRef(
    typeof window != "undefined" &&
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting && page <= maxpage) {
        setPage((no) => no + 1);
      }
    })
  );

  useEffect(() => {
    if (page <= maxpage) {
      getAssingMentlist();
    }
  }, [page]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentObserver == false) return;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const [checkTest, setcheckTest] = useState(false);

  const getAssingMentlist = () => {
    api_call_token
      // .get(`content/V1/course/listing/?course__id=${courseId}&questions__question_type=5&page=${page}`)
      .get(`content/V1/subjective/test/?course__id=${courseId}&quiz_type=2`)
      .then((res) => {
        const maxpage = res?.data?.max_pages;
        setmaxPage(maxpage);

        const datanew = res?.data?.data;
        for (let obj of datanew) {
          const { test } = obj;

          if (test.length > 0) {
            setcheckTest(true);
          }
        }

        setAssingmentList((prevState) => {
          const updatedState = [...prevState, ...datanew];
          return updatedState;

        });

        setSelectCourse(res?.data?.course?.title);
        // setAssingmentList(res?.data?.data);
        // setLoading(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(assingmentList, "1234");
  // useEffect(() => {
  //   getAssingMentlist();
  // }, []);

  // const handelTestAssignment = () =>{
  //   history.push(`/my-space/1/assingment`)
  // }

  return (
    <div className={styles.mainContainer}>
      {checkTest === false && (
        <div
          className={styles.nodataAvl}
          style={{ margin: "0 auto", width: "fit-content" }}
        >
          <img src={notest} alt="no Video" />
          <p style={{ fontSize: "20px", textAlign: "center" }}>
            No Assignment{" "}
          </p>
        </div>
      )}
      {checkTest === true && (
        <>
          {/* <h2 className={styles.CourseHeading}>{assingmentList[0]?.course}</h2>
      <h3 className={styles.heading}>Assignment</h3> */}
          {loading == 5 && (
            <div>
              <div className={styles.card}>
                <div className={styles.leftContainer}>
                  {/* <div className={styles.imageContainer}> */}
                  <Skeleton
                    className={styles.imageContainer}
                    animation="wave"
                    variant="rect"
                    width="100%"
                  // height={300}
                  // key={i}
                  />
                  {/* </div> */}
                  <h3 className={styles.title}>
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      width="auto"
                    // height={300}
                    // key={i}
                    />
                  </h3>
                </div>

                <div className={styles.questionDetails}>
                  <div className={styles.imgtext}>
                    <div className={styles.infoimg}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="auto"
                      // height={300}
                      // key={i}
                      />
                    </div>
                    <div className={styles.text}>
                      <h4>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="auto"
                        // height={300}
                        // key={i}
                        />
                      </h4>
                      <p>Max. Marks</p>
                    </div>
                  </div>
                  <div className={styles.imgtext}>
                    {/* <div className={styles.infoimg}> */}
                    <Skeleton
                      className={styles.infoimg}
                      animation="wave"
                      variant="rect"
                      width="auto"
                    // height={300}
                    // key={i}
                    />
                    {/* </div> */}
                    <div className={styles.text}>
                      <h4>
                        <Skeleton
                          animation="wave"
                          variant="rect"
                          width="auto"
                        // height={300}
                        // key={i}
                        />
                      </h4>
                      <p>Total Ques.</p>
                    </div>
                  </div>
                </div>
                {/* <button className={styles.teketestbtn}> */}
                <Skeleton
                  className={styles.teketestbtn}
                  animation="wave"
                  variant="rect"
                // text="take test"
                // width="auto"
                // height={300}
                // key={i}
                />
                {/* </button> */}
              </div>
            </div>
          )}
          {/* {loading == 1 && ( */}
          <div>
            {/* <Link  to='/assingment' >submit</Link> */}
            {assingmentList?.map((v, i) => {
              // if(v?.test == "") {
              //   setEmpty(true)
              // }
              return (
                <>
                  {v?.test != "" ? <h4 className={styles.chapterheading}> Chapter {i + 1}. {v?.title}</h4> : ""}
                  {v?.test != ""
                    ? v?.test?.map((info) => {
                      return (
                        <>
                          <div className={styles.card} ref={setLastElement}>
                            <div className={styles.leftContainer}>
                              {/* <div className={styles.imageContainer}>
                                  <img src={testimg} />
                                </div> */}
                              <h3 className={styles.title}>{info?.title}</h3>
                            </div>

                            <div className={styles.questionDetails}>
                              <div className={styles.imgtext}>
                                <div className={styles.infoimg}>
                                  <img src={testmark} />
                                </div>
                                <div className={styles.text}>
                                  <h4>{info?.marks}</h4>
                                  <p>Maximum Marks</p>
                                </div>
                              </div>
                              <div className={styles.imgtext}>
                                <div className={styles.infoimg}>
                                  <img src={testQues} />
                                </div>
                                <div className={styles.text}>
                                  <h4>{info?.total_question}</h4>
                                  <p>Total Questions</p>
                                </div>
                              </div>
                            </div>
                            <button
                              className={styles.teketestbtn}
                              onClick={() => {
                                info.test_submited === true ? alert("You already attempt the test") : onTakeTest(info);
                              }}
                            >
                              {info.test_submited === true ? "Attempted" : "Attempt"}

                            </button>
                          </div>
                        </>
                      );
                    })
                    : ""}
                </>
                // <div className={styles.card} ref={setLastElement}>
                //   <div className={styles.leftContainer}>
                //     <div className={styles.imageContainer}>
                //       <img src={testimg} />
                //     </div>
                //     <h3 className={styles.title}>{v?.title}</h3>
                //   </div>

                //   <div className={styles.questionDetails}>
                //     <div className={styles.imgtext}>
                //       <div className={styles.infoimg}>
                //         <img src={testmark} />
                //       </div>
                //       <div className={styles.text}>
                //         <h4>{v?.marks}</h4>
                //         <p>Max. Marks</p>
                //       </div>
                //     </div>
                //     <div className={styles.imgtext}>
                //       <div className={styles.infoimg}>
                //         <img src={testQues} />
                //       </div>
                //       <div className={styles.text}>
                //         <h4>{v?.total_question}</h4>
                //         <p>Total Ques.</p>
                //       </div>
                //     </div>
                //   </div>
                //   <button className={styles.teketestbtn} onClick={() => {onTakeTest(v);}}>Attempt</button>
                // </div>
              );
            })}
          </div>
        </>
      )}
      {/* )} */}
    </div>
  );
}

export default AssingmentPage;

const cardData = [
  {
    id: 1,
    img: testimg,
    title: " assignment 1",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
  {
    id: 2,
    img: testimg,
    title: " assignment 2",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
  {
    id: 3,
    img: testimg,
    title: " assignment 3",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
  {
    id: 4,
    img: testimg,
    title: " assignment 4",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
];
